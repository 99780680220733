import React from 'react'

import { translate } from '../../../common/translatable/translatable'

import { BrandItemProps } from './@types/brand-item.types'

interface GoogleStars {
  src: string;
  alt: string;
}

const BrandItem: React.FC<BrandItemProps> = ({
  img,
  alt,
  content,
  extraClasses,
  isGoogle,
  imgAlt
}) => {
  const GoogleStars = ({ src, alt }: GoogleStars) => (
    <div className="d-flex flex-column align-items-center">
      <div className="d-flex flex-row align-items-center">
        <img src={src} alt={alt} />
        <div className="d-flex flex-column justify-content-center font-size-15 ml-1 pt-1">
          <span>4.9</span>
        </div>
      </div>
      <div className="pt-1">
        <span>680 opinii</span>
      </div>
    </div>
  )

  return (<div
    style={{ height: '160px' }}
    className="d-flex flex-column align-items-center justify-content-start"
  >
    {img && (
      <div className="d-flex align-items-center" style={{ height: '60px' }}>
        <img src={img} className={`w-100 ${extraClasses}`} alt={alt} />
      </div>
    )}
    {content && (
      <div
        className="mt-2 text-md-center font-size-14 font-size-mobile-12 font-style-italic"
        style={{ color: '#989898' }}
      >
        {isGoogle ? <GoogleStars src={content} alt={alt} /> : translate(content)}
      </div>
    )}
    {!img && <div className="mt-2 font-weight-bold">{imgAlt}</div>}
          </div>)
}

export default BrandItem
